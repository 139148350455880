import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  background: ${props => props.bg};
`;

export const RouteWrap = styled.div`
  overflow: auto;
  display: flex;
  flex: 1;
`;