import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ProcessSpinner from 'components/ProcessSpinner';
import Header from 'components/Header';
import { useSelector } from 'react-redux';
import { authenticateStatus } from 'redux/auth/selectors';
import { Container, RouteWrap } from './styled';
import { useTheme } from '@material-ui/core';


const Auth = lazy(() => import('pages/Auth'));
const Main = lazy(() => import('pages/Main'));
const Website = lazy(() => import('pages/Website'));

const AppRouter = () => {
  const isAuthenticated = useSelector(authenticateStatus);
  const theme = useTheme();
  return (
    <Container bg={theme.palette.background.default}>
      <Router>
        <Suspense fallback={<ProcessSpinner />}>
          {isAuthenticated && <Header />}
          <RouteWrap>
            <Switch>
              <Route path="/admin/auth" component={Auth} exact />
              <PrivateRoute path="/admin" redirectTo="/admin/auth" component={Main} exact />
              <Route path="/" component={Website} exact />
            </Switch>
          </RouteWrap>
        </Suspense>
      </Router>
    </Container>
  );
}

export default AppRouter;