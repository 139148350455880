import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authenticateStatus } from 'redux/auth/selectors';

const PrivateRoute = ({ component: Component, redirectTo, isAuthenticate, ...rest }) => {
  const isAuthenticated = useSelector(authenticateStatus);
  return (
    <Route 
      {...rest}
      render={props => {
        if (isAuthenticated) return <Component {...props} />
        return <Redirect to={{pathname: redirectTo, from: rest.location}} />
      }}
    />
  );
}

export default PrivateRoute;