import React from 'react';
import { AppBar, Toolbar, Button, Typography } from '@material-ui/core';
import { Title } from './styled';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/auth';

const Header = () => {
  const dispatch = useDispatch();
  const logOut = () => dispatch(actions.signOut());
  
  return (
    <AppBar position="static">
      <Toolbar>
        <Title>
          <Typography variant="h6">GrifFinConsult</Typography>
        </Title>
        <Button color="inherit" onClick={logOut}>Log Out</Button>
      </Toolbar>
    </AppBar>
  );
}

export default Header;