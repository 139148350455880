import { createMuiTheme } from '@material-ui/core/styles';
import { indigo, grey } from '@material-ui/core/colors';

export const myTheme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        width: 320,
        background: indigo['500'],
        color: grey['50']
      }
    },
    MuiListItemIcon: {
      root: {
        color: grey['50']
      }
    }
  }
});