const initialState = {
  isAuthenticated: false,
  profile: null
};

export const authenticate = (state, { payload }) => ({
  ...state,
  isAuthenticated: true,
  token: payload.token
});

export const signOut = () => ({...initialState});

export default initialState;